@tailwind base;
@tailwind components;
@tailwind utilities;

.Quiz{
    @apply w-4/5 md:w-2/3 max-h-fit mx-auto bg-yellow-500 flex-col py-8
}

.Quiz-title{
    @apply text-white text-lg md:text-2xl
}

.Quiz-form{
    @apply mx-auto justify-around flex flex-wrap
}

.Quiz-card{
    @apply w-auto h-auto md:w-3/12 md:h-52 p-4 rounded-sm bg-white mt-4 mb-2 mx-1 active:border-4 active:border-yellow-500
}

.Quiz-card-option{
    @apply w-12 md:w-32 mx-auto mt-4 hover:cursor-pointer mb-1
}